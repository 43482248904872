declare var $: any;
import 'core-js/es/array';
import { VideoFunctions } from './videos';

export class InviteFunctions {

  private buttonPos: string = '';

  populateProductOptionId(optionId: number) {

    $('.invite').removeClass('selected');
    $('.optionscontainer__optionbox').removeClass('selected');
    $('.optionscontainer__optionbox--best').addClass('nobg');

    getCurrencyAndUpdateOption(optionId);

    $(`#join-${optionId}`).addClass('selected');
    $(`#optionbox-${optionId}`).addClass('selected');
    $('.submitbtn').removeClass('hideme');

  }

  openModalIfClickButton(event: any) {

    if (event) {
      const theevent = event || window.event;
      const { target: eventtarget } = theevent;
      this.buttonPos = eventtarget.id;
    }

    $('.invite-modal-dialog').addClass('invoked');
    $('.invite-focus').focus();
    $('body').addClass('modal-overlay');
    $('body').addClass('noscroll');

    $('.more-modal-dialog').removeClass('invoked');

    setTimeout(
      () => {
        $('.invite-modal-dialog').removeClass('loaded');
        $('.more-modal-dialog').addClass('loaded');
      },
      400,
    );

  }

  closeModalIfClickOutside(event: any) {
    const theevent = event || window.event;
    const { target: eventtarget } = theevent;
    if ($(eventtarget).hasClass('invite-modal-container') ||
      $(eventtarget).hasClass('invite-modal-close')) {
      if (document.location) {
        // document.location.href = '#';
        $('.invite-modal-dialog').removeClass('invoked');
        $('body').removeClass('noscroll');
        $('body').removeClass('modal-overlay');

        setTimeout(
          () => {
            $('.invite-modal-dialog').addClass('loaded');
          },
          400,
        );

      }
      this.populateProductOptionId(0);
    }
  }

  onClickEbookPermission() {

    const permissionBox = $('#BookRequest_MarketingPermission').length ?
      $('#BookRequest_MarketingPermission')[0] : null;

    if (permissionBox) {

      if (permissionBox.checked) {
        $('#EbookPermissionWarning').hide();
      } else {
        $('#EbookPermissionWarning').show();
      }
    }
  }

  initialise(isJoin: boolean) {

    if (isJoin) {
      $('body').removeClass('noscroll');
      $('body').removeClass('modal-overlay');
      window.localStorage.setItem('CP_submitPos', '-1');
      window.localStorage.setItem('CP_submitInvite', '0');
    }

    const submitPos: string | null = window.localStorage && window.localStorage.getItem('CP_submitPos');
    const submitInvite: string | null = window.localStorage && window.localStorage.getItem('CP_submitInvite');

    if (submitPos && submitPos !== '-1') {

      console.log(`submitPos: ${submitPos}`);

      window.addEventListener('load', () => {
        const navHeight: number = $('nav').height();
        const bPos: number = submitPos.indexOf('scroll--') === 0 ?
          parseInt(submitPos.substring(8), 10) :
          $(`.${submitPos}`).length ? ($(`.${submitPos}`).offset().top - navHeight) : 0;
        window.scrollTo(0, bPos);
        window.localStorage.setItem('CP_submitPos', '-1');
      });

      if (submitInvite && submitInvite === '1') {
        this.openModalIfClickButton(null);
      }

      window.localStorage.setItem('CP_submitInvite', '0');

    }

    const btns = Array.from(document.querySelectorAll('.populateoption'));
    let id: any;

    btns.forEach((btn: any) => {
      id = btn.id;
      const optionNumber = id.substring(id.indexOf('-') + 1);
      // btn.removeEventListener('click', () => this.populateProductOptionId);
      btn.addEventListener('click', () => this.populateProductOptionId(optionNumber));
    });

  }

  onCurrencyChange() {
    const dd = document.querySelector('#selectCurrency');
    if (dd) {
      const currency = $(dd).val();
      $('.optionscontainer__currencychange').addClass('hideme');
      // show all prices for the selected currency (eg for EUR element id starts with priceSpan_EUR)
      $(`[id^=priceSpan_${currency}]`).removeClass('hideme');
      getCurrencyAndUpdateOption(0);
    }
  }

  setupModal() {

    // add opacity animation after load to prevent FOC
    const modal = document.querySelector('#GetYourInvite');
    if (modal) {
      modal.classList.add('loaded');
    }

    // open modal on click button
    const btns = Array.from(document.querySelectorAll('.invite-btn'));

    btns.forEach((btn: any) => {
      // btn.removeEventListener('click', this.openModalIfClickButton);
      btn.addEventListener('click', this.openModalIfClickButton.bind(this));
    });

    // close modal on click outside modal
    const modalParent = document.querySelector('#modalParent');
    if (modalParent) {
      // modalParent.removeEventListener('click', this.closeModalIfClickOutside);
      modalParent.addEventListener('click', this.closeModalIfClickOutside.bind(this));
    }
  }

  linkIfClickTrial(event: any) {

    if (event) {
      const theevent = event || window.event;
      const { target: eventtarget } = theevent;
      this.buttonPos = eventtarget.id;
    }

    // window.location.href = `/join/free-trial${this.buttonPos.indexOf('option-1m') > -1 ? '-monthly' : this.buttonPos.indexOf('option-1y') > -1 ? '-annual' : ''}`;
    window.location.href = `/join/sign-up${this.buttonPos.indexOf('option-1m') > -1 ? '-monthly' : this.buttonPos.indexOf('option-1y') > -1 ? '-annual' : ''}`;

  }

  setupTrial() {

    // redirect to join
    const btns = Array.from(document.querySelectorAll('.trial-btn'));

    btns.forEach((btn: any) => {
      // btn.removeEventListener('click', this.openModalIfClickButton);
      if (!$(btn).hasClass('join-link')) {
        btn.addEventListener('click', this.linkIfClickTrial.bind(this));
      }
    });
  }

  setupForm() {
    // submit button animation if form input valid
    const inviteForm = document.querySelector('#inviteForm');
    if (inviteForm) {
      // inviteForm.removeEventListener('submit', () => this.onSubmit);
      inviteForm.addEventListener('submit', () => this.onSubmit(inviteForm));
    }

    const bookForm = document.querySelector('#ebookForm');
    if (bookForm) {
      // bookForm.removeEventListener('submit', () => this.onSubmitEbook);
      bookForm.addEventListener('submit', () => this.onSubmitEbook(event, bookForm));
    }

    const contactForm = document.querySelector('#contactForm');
    if (contactForm) {
      // bookForm.removeEventListener('submit', () => this.onSubmitEbook);
      contactForm.addEventListener('submit', () => this.onSubmitContact(event, contactForm));
    }

    const check = document.querySelector('#BookRequest_MarketingPermission');

    if (check) {
      // check.removeEventListener('click', () => this.onClickEbookPermission);
      check.addEventListener('click', () => this.onClickEbookPermission());
    }

    const coursesForm = document.querySelector('#coursesForm');
    if (coursesForm) {
      // redirect to join
      const btns = Array.from(document.querySelectorAll('.buy-btn'));

      btns.forEach((btn: any) => {
        btn.addEventListener('click', this.buyCourse.bind(this));
      });
    }

  }

  buyCourse(event: any) {

    if (event) {
      const theevent = event || window.event;
      const { target: eventtarget } = theevent;
      this.buttonPos = eventtarget.id;
    }

    const optionId: string = this.buttonPos.substring(this.buttonPos.indexOf('-') + 1);
    let selectedOptionInput: any = document.getElementById('txtBuyOptionId');
    selectedOptionInput.value = optionId;
    const selectedProductInput: any = document.getElementById(`txtBuyProductId-${optionId}`);
    selectedOptionInput = document.getElementById('txtBuyProductId');
    selectedOptionInput.value = selectedProductInput.value;

    const coursesForm : HTMLFormElement | null = document.querySelector('#coursesForm');
    if (coursesForm) coursesForm.submit();

  }

  onSubmit(form: any) {

    if (form.id === 'inviteForm') {
      $('.val-book').hide();
      $('.val-contact').hide();
      $('.val-invite').show();
    }

    if ($(form).valid()) {

      if (form.id === 'inviteForm') {

        window.localStorage.setItem('CP_submitPos', this.buttonPos);

        window.localStorage.setItem(
          'CP_submitInvite', '1');
      }

      afterSubmit(form);
    }
  }

  onSubmitEbook(event: Event | undefined, form: any) {

    $('.val-invite').hide();
    $('.val-book').show();

    $('#EbookPermissionWarning').hide();
    const permissionBox = $('#BookRequest_MarketingPermission').length ?
      $('#BookRequest_MarketingPermission')[0] : null;

    if (!permissionBox || !permissionBox.checked) {

      $('#EbookPermissionWarning').show();

      if (event) {
        event.preventDefault();
      }

      return false;
    }

    window.localStorage.setItem(
      'CP_submitInvite', '0');

    window.localStorage.setItem(
      'CP_submitPos',
      'ebook-container');

    if ($(form).valid()) {
      afterSubmit(form);
    }

    return true;
  }

  onSubmitContact(event: Event | undefined, form: any) {

    const recaptchaResult: any = document.getElementById('recaptcha-result');
    if (recaptchaResult.value === '0') {
       // alert('Please confirm you are a human');
      $('.recaptcha-val').show();
      event?.preventDefault();
      return false;
    }

    $('.recaptcha-val').hide();
    $('.val-invite').hide();
    $('.val-contact').show();

    window.localStorage.setItem(
      'CP_submitInvite', '0');

    window.localStorage.setItem(
      'CP_submitPos',
      'contact-form');

    if ($(form).valid()) {
      afterSubmit(form);
    }

    return true;
  }

}

function afterSubmit(form: any) {
  const submit = $(`#${form.id}-submit`);
  $(submit).prop('disabled', true);
  // submit.addClass('submitted');
  // $(submit).parent().hide();
  // const dummy = $(`#${form.id}-dummy`);
  // if (dummy) {
  //  dummy.addClass('submitted');
  // }
}

function getCurrencyAndUpdateOption(selectedMainOptionId: number) {

  const selectedOptionInput: any = document.getElementById('txtProductOptionId');
  const selectedMainOptionInput: any = document.getElementById('txtMainOptionId');
  const dd: any = document.getElementById('selectCurrency');
  let currency: string = 'GBP';

  if (selectedOptionInput) {

    if (dd) {
      currency = $(dd).val();
    }

    // if it's a button click we use the selected option passed to the function
    // if it's a currency change we get the currently selected (main) option from the hidden input
    const productOptionId: number = selectedMainOptionId !== 0 ?
      selectedMainOptionId : $('#txtMainOptionId').val();

    if (selectedMainOptionInput && selectedMainOptionId !== 0) {
      selectedMainOptionInput.value = String(selectedMainOptionId);
    }

    // we don't do anything if it was a currency change with an option not yet selected
    if (productOptionId !== 0) {
      const hidden = document.getElementById(`priceInput_${currency}_${productOptionId}`);

      if (hidden) {
        const currencyOptionId = $(hidden).val();
        // update hidden input with correct optionId for selected
        // currency and main / parent option
        selectedOptionInput.value = String(currencyOptionId);
      }

    }

  }
}
