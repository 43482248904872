export class CookieFunctions {

  private messageEvent: any = null;

  processMessage(e: any) {
    console.log('Message received');
    console.log(e.data);

    if (e.data.hasOwnProperty('cFrameHeight')) {

      console.log(e.data.cFrameHeight);

      const iframe: HTMLIFrameElement | null = <HTMLIFrameElement | null>
        document.getElementById('cookiesiframe');
      if (iframe) {
        if (e.data.cFrameHeight === 0) {
          iframe.classList.add('cookies-iframe--hide');
        } else {
          iframe.style.height = `${e.data.cFrameHeight + 2}px`;
          if (e.data.hasOwnProperty('customising') && e.data.customising) {
            iframe.classList.add('cookies-iframe--customising');
          } else {
            iframe.classList.remove('cookies-iframe--customising');
          }
        }
      }
    }
  }

  setupMessage() {
    this.messageEvent = this.processMessage.bind(this);
    window.addEventListener('message', this.messageEvent);
  }

}
