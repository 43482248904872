import siema from 'siema';
declare var $: any;
import 'core-js/es/array';

export class QuoteFunctions {

  private siemaInstance!: siema;

  gotoQuote(id: number) {
    this.siemaInstance.goTo(id);
  }

  setActiveQuote() {
    const currentSlide: number = this.siemaInstance.currentSlide;
    $('.quote-controller__circle').removeClass('quote-controller__circle--selected');
    $(`#qc-${currentSlide}`).addClass('quote-controller__circle--selected');
  }

  setupSiema() {

    this.siemaInstance = new siema({
      selector: '.quotes-siema',
      duration: 1250,
      easing: 'cubic-bezier(.17,.67,.32,1.34)',
      perPage: 1,
      startIndex: 0,
      draggable: true,
      multipleDrag: false,
      threshold: 20,
      loop: false,
      // rtl: false,
      onInit: () => {
        const circles = Array.from(document.querySelectorAll('.quote-controller__circle'));

        circles.forEach((circle: any) => {

          const id: number = parseInt(circle.id.substring(3), 10);

          circle.removeEventListener('click', () => {
            this.gotoQuote(id);
          });
          circle.addEventListener('click', () => {
            this.gotoQuote(id);
          });

        });
      },
      onChange: () => {
        this.setActiveQuote();
      },
    });

  }

  recreateSiema() {
    if (this.siemaInstance) {
      this.siemaInstance.destroy(true);
      this.setupSiema();
    }
  }

}
